import WorksitePhase from '../models/WorksitePhase';
import BaseCollection from '@/app/base/collections/BaseCollection';
import moment, { type MomentInstance } from '~~/utils/moment';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class WorksitePhaseCollection extends BaseCollection<WorksitePhase> {
    model: ModelType = ModelType.WORKSITE_PHASES;

    defaultItemSortCallback = (a: WorksitePhase, b: WorksitePhase) => {
        return a.startsAt?.isBefore(b.startsAt) ? -1 : 1;
    };

    timelineStartsAt(defaultValue: MomentInstance | null): MomentInstance | null {
        return this.items.length ? this.items[0].startsAt : defaultValue ?? moment();
    }

    timelineEndsAt(defaultValue: MomentInstance | null): MomentInstance | null {
        if (!this.items.length) return defaultValue ?? moment().add(14, 'days');
        return [...this.items].sort((a: WorksitePhase, b: WorksitePhase) => {
            return a.endsAt?.isAfter(b.endsAt) ? -1 : 1;
        })[0].endsAt;
    }

    whereDate(date: MomentInstance): WorksitePhase[] {
        return this.filter((worksitePhase: WorksitePhase) => {
            return date.isBetween(worksitePhase.startsAt, worksitePhase.endsAt, 'day', '[]');
        });
    }
}
