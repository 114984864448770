import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import moment, { type MomentInstance } from '~~/utils/moment';

export default class TimeActivityPause extends BaseModel {
    type: ModelType = ModelType.TIME_ACTIVITY_PAUSES;
    static type: ModelType = ModelType.TIME_ACTIVITY_PAUSES;
    prefix: string = 'planning:time-activity-pause';
    static prefix: string = 'planning:time-activity-pause';

    get startsAt(): MomentInstance | null {
        return this.attributes?.startsAt ? moment(this.attributes.startsAt) : null;
    }

    get endsAt(): MomentInstance | null {
        return this.attributes?.endsAt ? moment(this.attributes.endsAt) : null;
    }
}
