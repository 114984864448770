import Lead from '../models/Lead';
import BaseCollection from '@/app/base/collections/BaseCollection';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class LeadCollection extends BaseCollection<Lead> {
    model: ModelType = ModelType.LEADS;

    byStatus(status: string): Lead[] {
        return this.filter((lead: Lead) => lead.hasStatus(status));
    }
}
