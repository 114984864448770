import InvoiceLineCollection from '../collections/InvoiceLineCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class InvoiceSection extends BaseModel {
    type: ModelType = ModelType.INVOICE_SECTIONS;
    static type: ModelType = ModelType.INVOICE_SECTIONS;
    prefix = 'invoicing:invoice-section';
    static prefix = 'invoicing:invoice-section';

    get invoiceLines(): InvoiceLineCollection | null {
        return this.relationships?.invoiceLines?.data || null;
    }

    get title(): string | null {
        return this.attributes.title;
    }

    get defaultQuantity(): number | null {
        return this.attributes.defaultQuantity;
    }

    get displayTitle(): boolean {
        return this.attributes.displayTitle || true;
    }

    get displaySubtotal(): boolean {
        return this.attributes.displaySubtotal || true;
    }

    get displayContent(): boolean {
        return this.attributes.displayContent || true;
    }
}
