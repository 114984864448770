import { ModelEndpointResponse } from '../../base/endpoints/types';
import ImportedFileCollection from '../collections/ImportedFileCollection';
import ImportedFile from '../models/ImportedFile';
import { Importable } from '../enums/Importable';
import { ContactType } from '../../contact/enums/ContactType';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export interface ExportOptions {
    documentType: string;
    createdBefore?: any;
    createdAfter?: any;
}
export default class ImportedFileEndpoint extends BaseEndpoint<ImportedFile, ImportedFileCollection> {
    model: ModelType = ModelType.IMPORTED_FILE;
    path = 'tenant/common/files';
    // include = "test";

    async validated(file: File, documentType: string, contactType?: ContactType): Promise<ModelEndpointResponse<ImportedFile>> {
        // const payload: JsonRequestPayload = {
        //   method: "POST",
        //   path: "-actions/validated",
        //   data: {
        //     type: "imported-files",
        //     attributes: {
        //       documentType,
        //       file,
        //     },
        //   },
        // };
        const data = new FormData();
        data.append('data[type]', 'files');
        data.append('data[attributes][documentType]', documentType);
        if (contactType) data.append('data[attributes][contactType]', contactType);
        data.append('data[attributes][file]', file);
        return await this.customAction(null, 'validated', 'POST', data);
    }

    async upload(uuid: string, documentType: Importable, contactType?: ContactType): Promise<ModelEndpointResponse<ImportedFile>> {
        return await this.customAction(null, 'upload', 'POST', {
            data: {
                type: 'files',
                id: uuid,
                attributes: {
                    documentType,
                    contactType,
                },
            },
        });
    }

    async export({ documentType, createdBefore, createdAfter }: ExportOptions) {
        const data = {
            documentType,
            createdBefore,
            createdAfter,
        };
        console.log(data);
        const response = await this.customAction(null, 'export-async', 'POST', {
            data,
        });

        if (response.data) {
            this.sendEvent('created', response.data);
        }

        return response;
    }

    async exportBase({ documentType, createdBefore, createdAfter }: ExportOptions) {
        const data = {
            documentType,
            createdBefore,
            createdAfter,
        };
        console.log(data);
        const response = await this.customAction(null, 'export', 'POST', {
            data,
        });

        if (response.data) {
            this.sendEvent('created', response.data);
        }

        return response;
    }
    // const data = new FormData();
    // data.append("data[type]", "imported-files");
    // data.append("data[attributes][documentType]", "products");
    // return await this.customAction(null, "upload", "POST", data);
}
