import Worksite from '../models/Worksite';
import BaseCollection from '@/app/base/collections/BaseCollection';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class WorksiteCollection extends BaseCollection<Worksite> {
    model: ModelType = ModelType.WORKSITES;

    get globalProfitability() {
        return this.meta?.globalProfitability ?? null;
    }
}
