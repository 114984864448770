import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Contact from '~~/app/contact/models/Contact';
import Worksite from '~~/app/worksite/models/Worksite';
import moment, { type MomentInstance } from '~~/utils/moment';
import Pdf from '~~/app/pdf/models/Pdf';

export default class Expense extends BaseModel {
    type: ModelType = ModelType.EXPENSES;
    static type: ModelType = ModelType.EXPENSES;
    prefix = 'invoicing:expense';
    static prefix = 'invoicing:expense';

    get contact(): Contact | null {
        return this.relationships.contact?.data;
    }

    get worksite(): Worksite | null {
        return this.relationships.worksite?.data;
    }

    get pdf(): Pdf | null {
        return this.relationships.pdf?.data;
    }

    get title(): string {
        return this.attributes.title;
    }

    get description(): string {
        return this.attributes.description;
    }

    get amount(): number {
        return this.attributes.amount;
    }

    get date(): MomentInstance {
        return this.attributes.date ? moment(this.attributes.date) : null;
    }

    get expenseType(): string {
        return this.attributes.expenseType;
    }

    get expenseCategory(): string {
        return this.attributes.expenseCategory;
    }

    get paymentMethod(): string {
        return this.attributes.paymentMethod;
    }

    get dueAt(): MomentInstance {
        return this.attributes.dueAt ? moment(this.attributes.dueAt) : null;
    }

    get isPaid(): boolean {
        return this.attributes.isPaid;
    }

    get paidBy(): string {
        return this.attributes.paidBy;
    }

    get paidByDetails(): string {
        return this.attributes.paidByDetails;
    }

    get accountingId(): string {
        return this.attributes.accountingId;
    }

    get vatRate(): number {
        return this.attributes.vatRate;
    }

    get isLate(): boolean {
        return moment(this.dueAt).isBefore(moment()) && !this.isPaid;
    }

    edit(): void {
        navigateTo(`/invoicing/expenses/${this.id}/edit`);
    }
}
