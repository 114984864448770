import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class ProductUnit extends BaseModel {
    type: ModelType = ModelType.PRODUCT_UNITS;
    static type: ModelType = ModelType.PRODUCT_UNITS;
    prefix: string = 'inventory:product-unit';
    static prefix: string = 'inventory:product-unit';

    get title(): string {
        return this.attributes.title;
    }
}
