import WorksiteCollection from '../collections/WorksiteCollection';
import Worksite from '../models/Worksite';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class WorksiteEndpoint extends BaseEndpoint<Worksite, WorksiteCollection> {
    model: ModelType = ModelType.WORKSITES;
    path = 'tenant/worksite/worksites';
    include = 'users,managers,contact,tasks,tasks.worksitePhase';

    archive(uuid: string): Promise<void> {
        return this.customAction(uuid, 'archive');
    }

    unarchive(uuid: string): Promise<void> {
        return this.customAction(uuid, 'unarchive');
    }
}
