import CalendarEvent from './CalendarEvent';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Task from '@/app/task/models/Task';
import WorksitePhase from '@/app/worksite/models/WorksitePhase';
import moment, { type MomentInstance } from '~~/utils/moment';

export default class PlanningItem extends BaseModel {
    type: ModelType = ModelType.PLANNING_ITEMS;
    static type: ModelType = ModelType.PLANNING_ITEMS;
    prefix: string = 'planning:planning-item';
    static prefix: string = 'planning:planning-item';

    get model(): CalendarEvent | Task | WorksitePhase | null {
        return this.relationships?.model?.data;
    }

    get modelType(): string {
        return this.attributes.modelType;
    }

    get modelId(): string {
        return this.attributes.modelId;
    }

    get title(): string {
        return this.attributes.title;
    }

    get startsAt(): MomentInstance {
        return moment(this.attributes.startsAt);
    }

    get endsAt(): MomentInstance {
        return moment(this.attributes.endsAt);
    }

    toFullCalendarEvent(): any {
        if (this.modelType == 'worksite-phase') {
            return {
                id: this.getId(),
                title: this.title,
                start: this.startsAt?.startOf('day').toDateString(),
                end: this.endsAt?.endOf('day').toDateString(),
                allDay: true,
                stick: false,
                className: this.getId(),
            };
        }

        if (this.modelType == 'task') {
            return {
                id: this.getId(),
                title: this.title,
                start: this.startsAt?.startOf('day').toDateString(),
                allDay: true,
                stick: false,
                className: this.getId(),
            };
        }

        return {
            id: this.getId(),
            title: this.title,
            start: this.startsAt?.toISOString(),
            end: this.endsAt?.toISOString(),
            allDay: false,
            stick: false,
            className: this.getId(),
        };
    }
}
