import moment, { type MomentInstance } from '~~/utils/moment';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class BankAccount extends BaseModel {
    type: ModelType = ModelType.BANK_ACCOUNTS;
    static type: ModelType = ModelType.BANK_ACCOUNTS;
    prefix = 'bankaccount:bank-accounts';
    static prefix = 'bankaccount:bank-accounts';

    // get pdfImages(): PdfImageCollection | null {
    //   return this.relationships?.pdfImages?.data;
    // }

    get url(): string {
        return this.attributes.url;
    }

    get balance(): number {
        return this.attributes.balance;
    }

    get iban(): string {
        return this.attributes.iban;
    }

    get expiresAt(): MomentInstance {
        return moment(this.attributes.expiresAt);
    }

    get link(): string {
        return `/bankaccount/bank-accounts/${this.getId()}`;
    }
}
