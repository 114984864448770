import Worksite from './Worksite';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Invoice from '~/app/invoicing/models/Invoice';
import TimeActivity from '~/app/planning/models/TimeActivity';
import Expense from '~/app/invoicing/models/Expense';
import Pdf from '~/app/pdf/models/Pdf';

export default class WorksiteProfitabilityDocument extends BaseModel {
    type: ModelType = ModelType.WORKSITE_PROFITABILITY_DOCUMENTS;
    static type: ModelType = ModelType.WORKSITE_PROFITABILITY_DOCUMENTS;
    prefix: string = 'worksite:worksite-profitability-document';
    static prefix: string = 'worksite:worksite-profitability-document';

    get invoice(): Invoice | null {
        return this.relationships?.invoice?.data || null;
    }

    get pdf(): Pdf | null {
        return this.relationships.pdf?.data;
    }

    get timeActivity(): TimeActivity | null {
        return this.relationships?.timeActivity?.data || null;
    }

    get expense(): Expense | null {
        return this.relationships?.expense?.data || null;
    }

    get worksite(): Worksite | null {
        return this.relationships?.worksite?.data || null;
    }

    get title(): string {
        return this.attributes.title;
    }

    get documentType(): string {
        return this.attributes.documentType;
    }

    get amount(): number {
        return this.attributes.amount;
    }

    get status(): string {
        return this.attributes.status;
    }

    get hasStatus(): boolean {
        return this.status !== null && this.status !== 'null';
    }

    get isQuote(): boolean {
        return this.documentType == 'quote';
    }

    get isInvoice(): boolean {
        return this.documentType == 'invoice';
    }

    get isInvoicePaid(): boolean {
        return this.isInvoice && this.status == 'paid';
    }

    get isCost(): boolean {
        return this.documentType == 'expense' || this.documentType == 'labor' || this.documentType == 'subcontracting' || this.documentType == 'other';
    }

    get model(): Invoice | TimeActivity | Expense | null {
        if (this.invoice) {
            return this.invoice;
        }
        if (this.expense) {
            return this.expense;
        }
        if (this.timeActivity) {
            return this.timeActivity;
        }
        return null;
    }

    openModel(): void {
        if (this.invoice) {
            return this.invoice.edit();
        }
        if (this.expense) {
            return this.expense.edit();
        }
        if (this.timeActivity) {
            return this.timeActivity.preview();
        }
        return this.preview();
    }
}
