import ExpenseCollection from '../collections/ExpenseCollection';
import Expense from '../models/Expense';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { JsonRequestPayload } from '~~/app/base/endpoints/types';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export type ExpenseAccountingIdValidation = {
    isAvailable: boolean;
};

export default class ExpenseEndpoint extends BaseEndpoint<Expense, ExpenseCollection> {
    model: ModelType = ModelType.EXPENSES;
    path = 'tenant/invoicing/expenses';
    include = 'contact,worksite';

    async validateAccountingId(accountingId: string, expense?: Expense): Promise<ExpenseAccountingIdValidation | void> {
        const payload: JsonRequestPayload = {
            method: 'POST',
            data: {
                data: {
                    type: 'expense-validate-accounting-id',
                    attributes: {
                        accountingId,
                        expense: expense?.getId(),
                    },
                },
            },
            path: `-actions/validate/accounting-id`,
        };

        const response = await this.requestRaw(payload);
        const { data } = await response.json();
        if (!data?.attributes) return;

        const validationResponse: ExpenseAccountingIdValidation = {
            isAvailable: data?.attributes?.isAvailable,
        };
        return validationResponse;
    }

    async analyzeImage(file: File, form: any) {
        const formData = new FormData();
        formData.append('data[type]', 'expenses');
        formData.append('data[attributes][file]', file);
        const payload: JsonRequestPayload = {
            method: 'POST',
            data: formData,
            path: `-actions/ai-analyze?include=contact`,
        };

        const response = await this.requestRaw(payload);
        const { data, included } = await response.json();
        if (!response.ok) useToasteoError();
        if (!data?.attributes) return;

        if (!form.fields.title) {
            form.fields.title = data.attributes.title;
        }
        if (!form.fields.amount) {
            form.fields.amount = data.attributes.amount;
        }
        if (!form.fields.dueAt) {
            form.fields.dueAt = data.attributes.dueAt;
        }
        if (!form.fields.date || form.fields.date === moment().format('YYYY-MM-DD')) {
            form.fields.date = data.attributes.date;
        }
        if (form.fields.expenseCategory) {
            form.fields.expenseCategory = data.attributes.expenseCategory;
        }
        if (form.fields.expenseType) {
            form.fields.expenseType = data.attributes.expenseType;
        }

        if (form.fields.vatRate) {
            form.fields.vatRate = data.attributes.vatRate;
        }

        if (!form.fields.contact && included) {
            form.fields.contact = included[0];
        }

        return !!data.attributes;
    }
}
