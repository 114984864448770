import LeadStatus from '../enums/LeadStatus';
import InvoiceCollection from '../../invoicing/collections/InvoiceCollection';
import Source from './Source';
import User from '~~/app/auth/models/User';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Contact from '~~/app/contact/models/Contact';
import CalendarEventCollection from '~~/app/planning/collections/CalendarEventCollection';
import Worksite from '~~/app/worksite/models/Worksite';
import moment, { type MomentInstance } from '~~/utils/moment';
import ModelFileCollection from '@/app/common/collections/ModelFileCollection';
import TaskCollection from '@/app/task/collections/TaskCollection';
import Task from '@/app/task/models/Task';

export default class Lead extends BaseModel {
    type: ModelType = ModelType.LEADS;
    static type: ModelType = ModelType.LEADS;
    prefix = 'crm:lead';
    static prefix = 'crm:lead';

    get link(): string {
        return `/crm/lead/${this.getId()}`;
    }

    get relationshipsMap(): Record<string, ModelType> {
        return {
            tasks: ModelType.TASKS,
            worksite: ModelType.WORKSITES,
            user: ModelType.USERS,
            contact: ModelType.CONTACTS,
            source: ModelType.SOURCES,
            calendarEvents: ModelType.CALENDAR_EVENTS,
            invoices: ModelType.INVOICES,
            modelFiles: ModelType.MODEL_FILES,
        };
    }

    relationshipsMapped(): void {
        if (this.relationships.tasks && this.relationships.tasks.data && typeof this.relationships.tasks.data.isModelOrCollection === 'function') {
            this.relationships.tasks.data.map((task: Task) => task.setRelationship('lead', this.cloneWithoutRelationships(ModelType.LEADS)));
            this.relationships.tasks.data.setItemShouldBeInCollectionCallback((task: Task) => task.lead?.getId() === this.getId());
        }
    }

    get worksite(): Worksite | null {
        return this.relationships?.worksite?.data;
    }

    get user(): User | null {
        return this.relationships?.user?.data;
    }

    get source(): Source | null {
        return this.relationships?.source?.data;
    }

    get modelFiles(): ModelFileCollection | null {
        return this.relationships?.modelFiles?.data || null;
    }

    get contact(): Contact | null {
        return this.relationships?.contact?.data;
    }

    get calendarEvents(): CalendarEventCollection | null {
        return this.relationships?.calendarEvents?.data;
    }

    get tasks(): TaskCollection | null {
        return this.relationships?.tasks?.data;
    }

    get invoices(): InvoiceCollection | null {
        return this.relationships?.invoices?.data;
    }

    get title(): string {
        return this.attributes.title;
    }

    get status(): string {
        return this.attributes.status;
    }

    set status(status: string) {
        this.attributes.status = LeadStatus[status];
    }

    hasStatus(status: string): boolean {
        if (status == 'PENDING' && this.status == null) {
            return true;
        }

        return this.status === LeadStatus[status];
    }

    doesntHaveStatus(status: string): boolean {
        return !this.hasStatus(status);
    }

    get notes(): string {
        return this.attributes.notes;
    }

    set notes(notes: string) {
        this.attributes.notes = notes;
    }

    get addedAt(): MomentInstance | null {
        return this.attributes.addedAt ? moment(this.attributes.addedAt) : null;
    }

    get archivedAt(): MomentInstance | null {
        return this.attributes.archivedAt ? moment(this.attributes.archivedAt) : null;
    }

    set archivedAt(archivedAt: MomentInstance | null) {
        this.attributes.archivedAt = archivedAt;
    }

    get address(): Address | null {
        return this.attributes.address;
    }

    set address(address: Address | null) {
        this.attributes.address = address;
    }

    get totalQuoted(): number {
        return this.meta.totalQuoted;
    }
}
