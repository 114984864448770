import { v4 as uuidv4 } from 'uuid';
import BaseModel from '../models/BaseModel';
import { BaseSchema, ModelType } from '../schemas/BaseSchema';
import { CollectionInterface, CollectionInterfaceMeta } from '../endpoints/types';
import { useListen, useIgnore } from '@/composables/useEventBus';
import ModelFactory from '~~/app/factories/ModelFactory';
import ModelPrefix from '~~/app/factories/ModelPrefix';

abstract class BaseCollection<Model extends BaseModel> implements CollectionInterface {
    abstract model: ModelType;
    items: Model[];
    internalId: string;
    className: string;
    meta: CollectionInterfaceMeta;
    onUpdateCallback: Function | null = null;
    onUpdateVueCallback: Function | null = null;
    itemShouldBeInCollectionCallback: Function | null = null;
    defaultItemSortCallback: Function | null = null;
    modelCreatedEventCallback: Function | null = null;
    modelUpdatedEventCallback: Function | null = null;
    modelDeletedEventCallback: Function | null = null;
    onItemAdded: Function | null = null;
    onItemRemoved: Function | null = null;
    onItemUpdated: Function | null = null;

    constructor(schemas: Array<typeof BaseSchema>, included: Object = {}, meta: Object = {}) {
        const type = schemas[0]?.type;

        this.items = schemas.map((schema) => {
            return ModelFactory.make(type, schema, included);
        });
        this.items.forEach((item) => item.setOnUpdateCallback(() => this.onUpdate()));

        this.internalId = uuidv4();
        this.className = this.constructor.name;
        this.meta = meta;
    }

    static makeFromModels(models: Array<typeof BaseModel>): typeof self {
        const collection = new this([]);
        collection.items = models;
        collection.items.forEach((item) => item?.setOnUpdateCallback(() => collection.onUpdate()));
        return collection;
    }

    getInternalId(): string {
        return this.internalId;
    }

    isModelOrCollection(): boolean {
        return true;
    }

    isCollection(): boolean {
        return true;
    }

    getPagination(): JsonApiPagination {
        return this.meta?.page || {};
    }

    /**
     * Events Management, to make your collection reactive outside of Vue.
     */
    setup() {
        this.modelCreatedEventCallback = (item) => {
            this.add(item);
        };
        this.modelUpdatedEventCallback = (item) => {
            this.update(item);
        };
        this.modelDeletedEventCallback = (item) => {
            this.remove(item);
        };

        useListen(`${ModelPrefix.get(this.model)}:created`, this.modelCreatedEventCallback);
        useListen(`${ModelPrefix.get(this.model)}:updated`, this.modelUpdatedEventCallback);
        useListen(`${ModelPrefix.get(this.model)}:deleted`, this.modelDeletedEventCallback);

        for (const item of this.items) {
            item.setup();
        }
    }

    destroy() {
        for (const item of this.items) {
            item.destroy();
        }

        useIgnore(`${ModelPrefix.get(this.model)}:created`, this.modelCreatedEventCallback);
        useIgnore(`${ModelPrefix.get(this.model)}:updated`, this.modelUpdatedEventCallback);
        useIgnore(`${ModelPrefix.get(this.model)}:deleted`, this.modelDeletedEventCallback);
        this.onUpdateVueCallback = null;
        this.onItemAdded = null;
        this.onItemRemoved = null;
        this.onItemUpdated = null;
    }

    /**
     * Collection Helpers, super power your array of items.
     */
    clone(): BaseCollection<Model> {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }

    get(): Array<Model> {
        let items = this.items.filter((item) => this.itemShouldBeInCollection(item));
        if (this.defaultItemSortCallback) {
            items = items.sort(this.defaultItemSortCallback);
        }
        return items;
    }

    setDefaultItemSortCallback(callback: Function | null): void {
        this.defaultItemSortCallback = callback;
    }

    setItemShouldBeInCollectionCallback(callback: Function | null): void {
        this.itemShouldBeInCollectionCallback = callback;
    }

    itemShouldBeInCollection(item: Model): boolean {
        return this.itemShouldBeInCollectionCallback ? this.itemShouldBeInCollectionCallback(item) : true;
    }

    itemExistsInCollection(item: Model): boolean {
        return this.items.some((i) => i.getId() == item.getId());
    }

    add(item: Model): void {
        if (this.itemExistsInCollection(item)) {
            return;
        }

        if (!this.itemShouldBeInCollection(item)) {
            return;
        }

        this.addItem(item);
    }

    addItem(item: Model): void {
        // item.currentCollection = this;
        this.items.push(item);
        this.onUpdate();
        this.onItemAdded?.(item);
    }

    push(item: Model): void {
        console.warn('Please use collection.add(item) instead of collection.push(item).');
        this.add(item);
    }

    unshift(item: Model): void {
        if (this.itemExistsInCollection(item)) {
            return;
        }

        if (this.itemShouldBeInCollection(item)) {
            // item.currentCollection = this;
            this.items.unshift(item);
            this.onUpdate();
            this.onItemRemoved?.(item.getId());
        }
    }

    update(item: Model): void {
        if (this.itemExistsInCollection(item)) {
            this.onUpdate();
            this.onItemUpdated?.(item);
        }
    }

    remove(id: string): void {
        if (this.items.some((i) => i.getId() == id)) {
            this.items = this.items.filter((item) => item.getId() !== id);
            this.onUpdate();
            this.onItemRemoved?.(id);
        }
    }

    onUpdate(): void {
        this.internalId = uuidv4();
        this.onUpdateCallback?.();
        this.onUpdateVueCallback?.();
    }

    setOnUpdateCallback(callback: Function | null): void {
        this.onUpdateCallback = callback;

        for (const item of this.items) {
            item.setOnUpdateCallback(callback);
        }
    }

    setOnUpdateVueCallback(callback: Function | null): void {
        this.onUpdateVueCallback = callback;

        for (const item of this.items) {
            item.setOnUpdateVueCallback(callback);
        }
    }

    /**
     * Array Helpers, to make your collection feel like an array.
     */
    [Symbol.iterator]() {
        let index = -1;

        return {
            next: () => {
                index += 1;

                return {
                    value: this.get()[index],
                    done: index >= this.get().length,
                };
            },
        };
    }

    toArray(): Array<Model> {
        return this.get();
    }

    toIds(): Array<string> {
        return this.get().map((item) => item.getId());
    }

    count(): number {
        return this.length();
    }

    length(): number {
        return this.get().length;
    }

    isEmpty(): boolean {
        return this.length() === 0;
    }

    isNotEmpty(): boolean {
        return !this.isEmpty();
    }

    reversed(): Array<Model> {
        return this.get().reverse();
    }

    getAt(index: number): Model | null {
        return this.get()[index] ?? null;
    }

    findIndex(callback: Function): number {
        return this.get().findIndex(callback);
    }

    map<T>(callback: (model: Model) => T): Array<T> {
        return this.get().map(callback);
    }

    pluck(property: string): Array<any> {
        return this.get().map((item) => item[property as keyof Model]);
    }

    forEach(callback: Function): Array<Model> {
        return this.get().forEach(callback);
    }

    filter(callback: Function): Array<Model> {
        return this.get().filter(callback);
    }

    filterByProperty(property: string, value: any): Array<Model> {
        return this.get().filter((item) => item[property] == value);
    }

    find(callback: Function): Model | null {
        return this.get().find(callback);
    }

    findBy(attribute: string, value): Model | null {
        return this.get().find((item) => item[attribute] == value) ?? null;
    }

    findById(id: string): Model | null {
        return this.get().find((item) => item.getId() === id) ?? null;
    }

    filterByIds(ids: Array<string>): Array<Model> {
        return this.get().filter((item) => ids.includes(item.getId()));
    }

    first(): Model | null {
        return this.get()[0] ?? null;
    }

    last(): Model | null {
        return this.get()[this.length() - 1] ?? null;
    }

    some(callback: Function): Array<Model> {
        return this.get().some(callback);
    }

    every(callback: Function): Array<Model> {
        return this.get().every(callback);
    }

    sort(callback: Function): Array<Model> {
        return this.get().sort(callback);
    }

    sortBy(property: string, direction: 'asc' | 'desc' = 'asc'): Array<Model> {
        return this.get().sort((a, b) => {
            if (a[property as keyof Model] < b[property as keyof Model]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[property as keyof Model] > b[property as keyof Model]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    sortByMoment(property: string, direction: 'asc' | 'desc' = 'asc'): Array<Model> {
        return this.get().sort((a, b) => {
            if (a[property as keyof Model].isBefore(b[property as keyof Model])) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[property as keyof Model].isAfter(b[property as keyof Model])) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    slice(callback: Function): Array<Model> {
        return this.get().slice(callback);
    }

    reduce(callback: Function): Array<Model> {
        return this.get().reduce(callback);
    }

    sum(property: string): number {
        return this.get().reduce((sum, item) => sum + item[property as keyof Model], 0);
    }

    max(property: string): number {
        return Math.max(...this.get().map((item) => item[property as keyof Model]));
    }

    includes<Model extends BaseModel>(model: Model): boolean {
        return this.get().filter((item) => item.is(model)).length > 0;
    }

    includesAny<Model extends BaseModel>(models: Array<Model>): boolean {
        return this.get().filter((item) => item.isAny(models)).length > 0;
    }

    uniquePropertyValues(property: string, valueCallback?: Function): Array<string> {
        const values: Array<string> = [];

        for (const item of this.get()) {
            const value = valueCallback ? valueCallback(item, property) : item[property as keyof Model];
            if (!values.includes(value)) {
                values.push(value);
            }
        }

        return values;
    }
}

export default BaseCollection;
