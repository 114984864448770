import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class VatRate extends BaseModel {
    type: ModelType = ModelType.VAT_RATES;
    static type: ModelType = ModelType.VAT_RATES;
    prefix = 'invoicing:vat-rate';
    static prefix = 'invoicing:vat-rate';

    get title(): string {
        return this.attributes.title;
    }

    get rate(): number {
        return this.attributes.rate;
    }

    get displayRate(): string {
        return `${this.rate}%`;
    }

    get belgiumCocontractorMention(): boolean {
        return this.attributes.belgiumCocontractorMention;
    }

    get belgiumRenovationMention(): boolean {
        return this.attributes.belgiumRenovationMention;
    }

    get franceCocontractorMention(): boolean {
        return this.attributes.franceCocontractorMention;
    }

    get franceRequireVatCertificate(): boolean {
        return this.attributes.franceRequireVatCertificate;
    }
}
