import { v4 as uuidv4 } from 'uuid';
import { BaseSchema, Attributes, Meta, Relationships, ModelType } from '../schemas/BaseSchema';
import BaseCollection from '../collections/BaseCollection';
import { ModelInterface } from '../endpoints/types';
import ModelFactory from '~~/app/factories/ModelFactory';
import CollectionFactory from '~~/app/factories/CollectionFactory';
import moment, { type MomentInstance } from '~~/utils/moment';

abstract class BaseModel implements ModelInterface {
    /**
     * Your model's type should match the JSON:API type.
     * Ex: User -> users
     */
    abstract type: ModelType;

    /**
     * Your model's prefix is used for events and modals.
     * It should start with the module's name then the model's name in singular case.
     * Ex: TimeActivity -> planning:time-activity
     */
    abstract prefix: string;

    /**
     * The available relationships for your model.
     * This is used to map empty relationships to a Collection.
     */
    get relationshipsMap(): Record<string, ModelType> {
        // Implement this in your model if needed.
        return {};
    }

    /**
     * The method to run after relationships have been mapped.
     */
    relationshipsMapped(): void {
        // Implement this in your model if needed.
    }

    /**
     * The model's content.
     */
    private internalId: string;
    protected id: string;
    protected attributes: Attributes;
    protected meta: Meta;
    protected relationships: Partial<Relationships>;
    protected included: Array<typeof BaseSchema>;
    onUpdateCallback: Function | null = null;
    onUpdateVueCallback: Function | null = null;
    onModelUpdatedEventCallback?: Function | null;
    onModelDeletedEventCallback?: Function | null;

    /**
     * Initializing a new model requires the model's schema and potentially an array of included models to map relationships.
     * If no schema is provided, the model will be empty and should only be used for retrieving classes via static methods.
     */
    constructor(schema: BaseSchema<Attributes, Meta, Relationships> | null = null, included: Array<typeof BaseSchema> = []) {
        this.internalId = uuidv4();
        this.id = schema?.id ? `${schema?.id}` : uuidv4();
        this.attributes = schema?.attributes || {};
        this.relationships = schema?.relationships || {};
        this.meta = schema?.meta || {};
        this.included = included;

        this.mapIncludedModels();
    }

    isModelOrCollection(): boolean {
        return true;
    }

    getAttributes(): Attributes {
        return this.attributes;
    }

    getId(): string {
        return this.id;
    }

    getInternalId(): string {
        return this.internalId;
    }

    setInternalId(id?: string | null): void {
        this.internalId = id ?? uuidv4();
    }

    getTranslation(key: string, locale?: string): string | null {
        const translation = useTranslation();
        locale = locale || translation.getCurrentLocale();
        return this.attributes?.[key][locale] || null;
    }

    get createdAt(): MomentInstance | null {
        return this.attributes?.createdAt ? moment(this.attributes.createdAt) : null;
    }

    get updatedAt(): MomentInstance | null {
        return this.attributes?.updatedAt ? moment(this.attributes.updatedAt) : null;
    }

    clone(modelType: ModelType): typeof BaseModel {
        return ModelFactory.make(modelType, {
            id: this.getId(),
            attributes: this.attributes,
            meta: this.meta,
            relationships: this.relationships,
        });
    }

    cloneWithoutRelationships(modelType: ModelType): typeof BaseModel {
        return ModelFactory.make(modelType, {
            id: this.getId(),
            attributes: this.attributes,
            meta: this.meta,
        });
    }

    setRelationship(relationship: string, data: typeof BaseCollection | typeof BaseModel | null): void {
        if (!this.relationships) {
            this.relationships = {};
        }
        if (!this.relationships[relationship]) {
            this.relationships[relationship] = {};
        }
        this.relationships[relationship].data = data;
    }

    /**
     * Try to map the included models array with the relationships object.
     * A loaded relationship will have a data property, which is either an object or an array of objects.
     * For relationships with a single object, we map it to the related Model.
     * For relationships with an array of objects, we first create an array of schemas then a Collection.
     * For relationships with the data property set to an empty array, we create an empty Collection for reactivity.
     *
     * When a relationship is updated, we need to notify the parent model and/or collection for reactivity.
     */
    mapIncludedModels(): void {
        for (const key in this.relationships) {
            if (this.relationships[key].data) {
                // console.log('Trying to include model(s)', key,  Array.isArray(this.relationships[key].data));
                const value = Array.isArray(this.relationships[key].data) ? this.mapToManyRelationshipsWithIncludedModels(key) : this.mapToOneRelationshipsWithIncludedModels(key);

                this.setRelationship(key, value);

                if (this.relationships[key].data) {
                    this.relationships[key].data.setOnUpdateCallback(() => this.onUpdate());
                }
            }
        }

        this.relationshipsMapped();
    }

    relationshipMatchesIncluded(relationData: any, included: BaseSchema<Attributes, Meta, Relationships>): boolean {
        return relationData.type == included.type && relationData.id == `${included.id}`;
    }

    mapToOneRelationshipsWithIncludedModels(key: string): typeof BaseModel | null {
        for (let i = 0; i < this.included.length; i++) {
            if (this.relationshipMatchesIncluded(this.relationships[key].data, this.included[i])) {
                // console.log('Mapped with toOne relationship', key);
                return ModelFactory.make(this.included[i].type, this.included[i], this.included);
            }
        }

        return null;
    }

    mapToManyRelationshipsWithIncludedModels(key: string): typeof BaseCollection | null {
        const loadedRelationships = this.relationships[key].data as Array<any>;

        if (loadedRelationships.length == 0) {
            if (!this.relationshipsMap || !this.relationshipsMap.hasOwnProperty(key)) {
                console.warn('A relationship was loaded but does not contain an item, no relationships map has been found matching it...', this, key, this.relationshipsMap);
                return null;
            }
            return CollectionFactory.make(this.relationshipsMap[key], [], this.included);
        }

        const schemas: Array<BaseSchema<Attributes, Meta, Relationships>> = [];
        for (let i = 0; i < loadedRelationships.length; i++) {
            for (let j = 0; j < this.included.length; j++) {
                if (loadedRelationships[i] && this.relationshipMatchesIncluded(loadedRelationships[i], this.included[j])) {
                    schemas.push(this.included[j]);
                }
            }
        }

        if (schemas.length > 0) {
            // console.log('Mapped with toMany relationship', key);
        }

        return schemas.length > 0 ? CollectionFactory.make(schemas[0].type, schemas, this.included) : null;
    }

    hasRelationship(relationship: string): boolean {
        return this.relationships && this.relationships.hasOwnProperty(relationship) && this.relationships[relationship].data;
    }

    getRelationshipCount(relationship: string): number | null {
        return this.relationships?.[relationship]?.meta?.count || null;
    }

    /**
     * Events Management, to make your model reactive outside of Vue.
     */
    setup(): void {
        this.onModelUpdatedEventCallback = (item) => {
            this.refresh(item);
        };
        this.onModelDeletedEventCallback = (id: string) => {
            this.onModelDestroyed(id);
        };

        useListen(`${this.prefix}:updated`, this.onModelUpdatedEventCallback);
        useListen(`${this.prefix}:deleted`, this.onModelDeletedEventCallback);

        for (const key in this.relationships) {
            if (this.relationships[key].data && typeof this.relationships[key].data.isModelOrCollection === 'function' && this.relationships[key].data.isModelOrCollection()) {
                this.relationships[key].data.setup();
            }
        }
    }

    destroy(): void {
        useIgnore(`${this.prefix}:updated`, this.onModelUpdatedEventCallback);
        useIgnore(`${this.prefix}:deleted`, this.onModelDeletedEventCallback);
        this.onUpdateVueCallback = null;
    }

    refresh<Model extends BaseModel>(newModel: Model): void {
        // console.log('Check for refresh', {
        //     new_model: newModel,
        //     current_model: this,
        //     current_modelId: this.getId(),
        //     refresh: newModel.getId == this.getId,
        // });
        if (newModel.getId() !== this.getId()) {
            return;
        }
        // TODO: Check if current version is newer than the one we are refreshing

        // console.log("refresh?");
        this.initOnRefresh(newModel);
        this.onUpdate();
    }

    initOnRefresh<Model extends BaseModel>(newModel: Model): void {
        this.internalId = newModel.internalId;
        this.attributes = newModel.attributes;
        this.meta = newModel.meta || {};

        /**
         * Just in case the newest model doesn't contain the same relationships as the current one.
         * We only replace the relationships that are present in the new model.
         */
        if (newModel.relationships) {
            for (const key in newModel.relationships) {
                if (typeof this.relationships[key] === 'undefined') {
                    this.relationships[key] = newModel.relationships[key];
                    continue;
                }

                if (typeof newModel.relationships[key].data !== 'undefined') {
                    this.relationships[key].data = newModel.relationships[key].data;
                }

                if (typeof newModel.relationships[key].meta !== 'undefined') {
                    this.relationships[key].meta = newModel.relationships[key].meta;
                }
            }
        }
    }

    onUpdate(): void {
        // console.log("Model updated", this);
        this.onUpdateCallback?.();
        this.onUpdateVueCallback?.();
    }

    setOnUpdateCallback(callback: Function | null): void {
        this.onUpdateCallback = callback;

        for (const key in this.relationships) {
            if (this.relationships[key].data && typeof this.relationships[key].data.isModelOrCollection === 'function' && this.relationships[key].data.isModelOrCollection()) {
                this.relationships[key].data.setOnUpdateCallback(callback);
            }
        }
    }

    setOnUpdateVueCallback(callback: Function | null): void {
        this.onUpdateVueCallback = callback;

        for (const key in this.relationships) {
            if (this.relationships[key].data && typeof this.relationships[key].data.isModelOrCollection === 'function' && this.relationships[key].data.isModelOrCollection()) {
                this.relationships[key].data.setOnUpdateVueCallback(callback);
            }
        }
    }

    onModelDestroyed(id: string): void {
        if (this.id !== id) {
            return;
        }

        this.destroy();
    }

    is<Model extends BaseModel>(model: Model): boolean {
        return this.getId() == model.getId();
    }

    isAny<Model extends BaseModel>(models: Model[]): boolean {
        return models.some((model) => this.is(model));
    }

    /**
     * Return true is any of the attributes has changed compared to the current model.
     * Useful if you want to give the option to save the new version in the database.
     */
    hasChanged(attributes: Attributes): boolean {
        for (const key in attributes) {
            if (attributes[key] !== this.attributes[key]) {
                return true;
            }
        }
        return false;
    }

    /**
     * Actions to open the modal for this model, the page, the form etc...
     */
    open() {
        if (!this.link) {
            return console.warn('Cannot open a model without a link.');
        }
        return navigateTo(this.link);
    }

    get link(): string {
        return '';
    }

    openFromSearch(): void {
        this.link ? navigateTo(this.link) : this.preview();
    }

    preview(): void {
        useEvent(`${this.prefix}:modal:open`, { model: this });
    }

    openContextMenu(event: Event): void {
        useEvent(`${this.prefix}:context-menu:open`, { model: this, event });
    }

    edit(extraPayload?: any): void {
        // console.log({ trigered: "edit" });
        // console.log("Edit model", extraPayload, {
        //     ...{ model: this },
        //     ...extraPayload,
        // });
        useEvent(`${this.prefix}:form-modal:open`, {
            ...{ model: this },
            ...extraPayload,
        });
    }

    getDownloadUrl(): string | null {
        return null;
    }

    isDownloadable() {
        return this.getDownloadUrl() !== null;
    }

    download(): void {
        if (!this.isDownloadable()) {
            return console.warn('Cannot download a model without a download url.');
        }

        window.open(this.getDownloadUrl(), '_blank');
    }
}

export default BaseModel;
