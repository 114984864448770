import ModelFile from '../models/ModelFile';
import BaseCollection from '@/app/base/collections/BaseCollection';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class ModelFileCollection extends BaseCollection<ModelFile> {
    model: ModelType = ModelType.MODEL_FILES;

    get breadcrumbs(): Array<BreadcrumbInterface> {
        return this.meta.breadcrumbs || [];
    }
}
