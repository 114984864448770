import WorksiteProfitabilityDocumentCollection from '../collections/WorksiteProfitabilityDocumentCollection';
import WorksiteProfitabilityDocument from '../models/WorksiteProfitabilityDocument';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class WorksiteProfitabilityDocumentEndpoint extends BaseEndpoint<WorksiteProfitabilityDocument, WorksiteProfitabilityDocumentCollection> {
    model: ModelType = ModelType.WORKSITE_PROFITABILITY_DOCUMENTS;
    path = 'tenant/worksite/worksite-profitability-documents';
    include = 'invoice,expense,timeActivity,expense.pdf,pdf';
}
