import { type Duration } from 'moment';
import TimeActivity from '../models/TimeActivity';
import moment from '~~/utils/moment';

interface TimeActivityLiveTimerInterface {
    loadedAt: string;
    duration: number;
}

export default class TimeActivityLiveTimer {
    timeActivity: TimeActivity;
    liveTimer: TimeActivityLiveTimerInterface;

    constructor(timeActivity: TimeActivity) {
        if (!timeActivity.meta?.liveTimer) {
            throw new Error('TimeActivityLiveTimer: TimeActivity has no liveTimer meta');
        }

        this.timeActivity = timeActivity;
        this.liveTimer = timeActivity.meta.liveTimer;
    }

    diffInDays(): number {
        return this.timeActivity.endsAtOrNow.diff(this.timeActivity.startsAt, 'days');
    }

    duration(): number {
        if (this.timeActivity.isCompleted()) {
            return this.liveTimer.duration;
        }

        const loadedAt = moment(this.liveTimer.loadedAt);
        const differenceInSeconds = moment().diff(loadedAt, 'seconds');
        return this.liveTimer.duration + differenceInSeconds;
    }

    get(): Duration {
        return moment.duration(this.duration(), 'seconds');
    }
}
