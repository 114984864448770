import UserSchema from '../schemas/UserSchema';
import User from '../models/User';
import UserCollection from '../collections/UserCollection';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import camelCaseKeys from '~~/utils/camelCaseKeys';

export interface AuthResponse {
    token?: string | null;
    user?: User | null;
    response: Response;
    validationErrors: Record<string, string[]> | null;
    json: any;
}

export default class UserEndpoint extends BaseEndpoint<User, UserCollection> {
    model: ModelType = ModelType.USERS;
    path = 'tenant/common/users';

    getAuthRequestHeaders(): HeadersInit {
        const translation = useTranslation();
        const headers: HeadersInit = new Headers();
        headers.set('Content-Type', 'application/json');
        headers.set('Accept', 'application/json');
        headers.set('Accept-Language', translation?.getCurrentLocale() || useGetDefaultLocale());

        return headers;
    }

    getAuthApiUrl(appendedUrl?: string): string {
        const {
            public: { trustupIoAuthUrl },
        } = useRuntimeConfig();

        return `${trustupIoAuthUrl}/api/${appendedUrl}`;
    }

    async login(email: string, password: string): Promise<AuthResponse> {
        const response = await fetch(this.getAuthApiUrl('login'), {
            method: 'POST',
            headers: this.getAuthRequestHeaders(),
            body: JSON.stringify({ email, password }),
        });
        const json = await response.json();
        const authResponse: AuthResponse = {
            response,
            json,
        };

        if (!response.ok) {
            console.error('Failed to login.', response, authResponse);
            return authResponse;
        }

        authResponse.user = this.getUserFromResponse(json);
        authResponse.token = this.getTokenFromResponse(json);
        return authResponse;
    }

    async register(data: { email: string; password: string; firstName: string; lastName: string }): Promise<AuthResponse> {
        const response = await fetch(this.getAuthApiUrl('register'), {
            method: 'POST',
            headers: this.getAuthRequestHeaders(),
            body: JSON.stringify({
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                password: data.password,
                locale: useTranslation()?.getCurrentLocale() ?? useGetDefaultLocale(),
            }),
        });
        const json = await response.json();
        const authResponse: AuthResponse = {
            response,
            json,
        };

        if (!response.ok) {
            if (json.errors) {
                authResponse.validationErrors = json.errors;
            }

            console.error('Failed to register.', response, authResponse);
            return authResponse;
        }

        authResponse.user = this.getUserFromResponse(json);
        authResponse.token = this.getTokenFromResponse(json);
        return authResponse;
    }

    async authed(impersonationToken?: string): Promise<User | null> {
        const authStore = useAuthStore();
        const token = impersonationToken || authStore.token;

        if (!token) {
            return null;
        }

        const headers = this.getAuthRequestHeaders();
        headers.set('Authorization', token);

        const response = await fetch(this.getAuthApiUrl('user'), {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            console.error('Failed to retrieve user.');
            return null;
        }

        const json = await response.json();
        return this.getUserFromResponse(json);
    }

    getUserFromResponse(json: any): User | null {
        if (!json.user) {
            return null;
        }

        return new User(
            new UserSchema({
                attributes: camelCaseKeys(json.user),
                id: json.user.id,
            })
        );
    }

    getTokenFromResponse(json: any): string | null {
        if (!json.token) {
            return null;
        }

        return `${json.token.token_type} ${json.token.access_token}`;
    }

    async updateUserData(id: string, path: string, attributes: any): Promise<ModelEndpointResponse<User>> {
        return await this.customAction(id, path, 'PATCH', {
            data: {
                type: 'users',
                id,
                attributes,
            },
        });
    }

    async updatePivot(id: string, attributes: any): Promise<ModelEndpointResponse<User>> {
        return await this.updateUserData(id, 'pivot', attributes);
    }

    async updateAvatar(id: string, avatar: string): Promise<ModelEndpointResponse<User>> {
        return await this.updateUserData(id, 'avatar', { avatar });
    }

    async updatePassword(id: string, data: { password: string; passwordConfirmation: string }): Promise<ModelEndpointResponse<User>> {
        return await this.updateUserData(id, 'password', {
            password: data.password,
            password_confirmation: data.passwordConfirmation,
        });
    }
}
