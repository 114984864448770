import InvoiceSectionCollection from '../collections/InvoiceSectionCollection';
import InvoicePaymentCollection from '../collections/InvoicePaymentCollection';
import InvoiceDiscountCollection from '../collections/InvoiceDiscountCollection';
import InvoiceDiscount from './InvoiceDiscount';
import WasteData from './WasteData';
import User from '~~/app/auth/models/User';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import Contact from '~~/app/contact/models/Contact';
import Worksite from '~~/app/worksite/models/Worksite';
import moment, { type MomentInstance } from '~~/utils/moment';
import InvoiceRoute from '~~/classes/InvoiceRoute';
import Lead from '~/app/crm/models/Lead';
import ModelEventCollection from '@/app/common/collections/ModelEventCollection';
import ModelFileCollection from '@/app/common/collections/ModelFileCollection';
import WorksiteProgressBilling from '~/app/worksite/models/WorksiteProgressBilling';
import WorksiteProgressBillingCollection from '~/app/worksite/collections/WorksiteProgressBillingCollection';

export default class Invoice extends BaseModel {
    type: ModelType = ModelType.INVOICES;
    static type: ModelType = ModelType.INVOICES;
    prefix = 'invoicing:invoice';
    static prefix = 'invoicing:invoice';

    get relationshipsMap(): Record<string, ModelType> {
        return {
            invoiceSections: ModelType.INVOICE_SECTIONS,
            modelEvents: ModelType.MODEL_EVENTS,
            modelFiles: ModelType.MODEL_FILES,
            invoicePayments: ModelType.INVOICE_PAYMENTS,
            invoiceDiscounts: ModelType.INVOICE_DISCOUNTS,
            wasteDataCollection: ModelType.WASTE_DATAS,
            worksiteProgressBilling: ModelType.WORKSITE_PROGRESS_BILLINGS,
            worksiteProgressBillings: ModelType.WORKSITE_PROGRESS_BILLINGS,
        };
    }

    get worksiteProgressBilling(): WorksiteProgressBilling | null {
        return this.relationships?.worksiteProgressBilling?.data || null;
    }

    get worksiteProgressBillings(): WorksiteProgressBillingCollection | null {
        return this.relationships?.worksiteProgressBillings?.data || null;
    }

    get invoiceSections(): InvoiceSectionCollection | null {
        return this.relationships?.invoiceSections?.data || null;
    }

    get modelEvents(): ModelEventCollection | null {
        return this.relationships?.modelEvents?.data || null;
    }

    get modelFiles(): ModelFileCollection | null {
        return this.relationships?.modelFiles?.data || null;
    }

    get invoicePayments(): InvoicePaymentCollection | null {
        return this.relationships?.invoicePayments?.data || null;
    }

    get invoiceDiscounts(): InvoiceDiscountCollection | null {
        return this.relationships?.invoiceDiscounts?.data || null;
    }

    getInvoiceDiscountsWithAppliedBeforeTaxStatus(status: boolean): InvoiceDiscount[] {
        if (this.invoiceDiscounts) {
            return this.invoiceDiscounts.filter((discount: InvoiceDiscount) => discount.appliedBeforeTax === status);
        }
        return [];
    }

    get user(): User | null {
        return this.relationships?.user?.data ?? null;
    }

    get contact(): Contact | null {
        return this.relationships?.contact?.data ?? null;
    }

    get wasteDataCollection(): WasteData | null {
        return this.relationships?.wasteDataCollection?.data ?? null;
    }

    get worksite(): Worksite | null {
        return this.relationships?.worksite?.data ?? null;
    }

    get lead(): Lead | null {
        return this.relationships?.lead?.data ?? null;
    }

    get numberOrDraft(): string {
        const { t } = useTranslation();
        return this.number || t('invoice_status.draft');
    }

    get documentType(): InvoiceDocumentType {
        return this.attributes.documentType;
    }

    get status(): string {
        return this.attributes.status;
    }

    get number(): string | null {
        return this.attributes.number;
    }

    get internalNumber(): number | null {
        return this.attributes.internalNumber;
    }

    get subtotal(): number | null {
        return this.attributes.subtotal;
    }

    get totalTax(): number | null {
        return this.attributes.totalTax;
    }

    get total(): number | null {
        return this.attributes.total;
    }

    get totalPaid(): number | null {
        return this.attributes.totalPaid;
    }

    get totalDue(): number | null {
        return this.attributes.totalDue;
    }

    get pdfId(): string | null {
        return this.attributes.pdfId;
    }

    get title(): string | null {
        return this.attributes.title;
    }

    get description(): string | null {
        return this.attributes.description;
    }

    get conditions(): string | null {
        return this.attributes.conditions;
    }

    get belgianStructuredCommunication(): string {
        return this.attributes.belgianStructuredCommunication;
    }

    get dueAt(): MomentInstance | null {
        return this.attributes.dueAt ? moment(this.attributes.dueAt) : null;
    }

    get sentAt(): MomentInstance | null {
        return this.attributes.sentAt ? moment(this.attributes.sentAt) : null;
    }

    get customCreatedAt(): MomentInstance | null {
        return this.attributes.customCreatedAt ? moment(this.attributes.customCreatedAt) : null;
    }

    get trueDate(): MomentInstance {
        return moment(this.attributes.trueDate);
    }

    get archivedAt(): MomentInstance | null {
        return this.attributes.archivedAt ? moment(this.attributes.archivedAt) : null;
    }

    get inspectedAt(): MomentInstance | null {
        return this.attributes.inspectedAt ? moment(this.attributes.inspectedAt) : null;
    }

    get energyAfterRenovation(): string | null {
        return this.attributes.energyAfterRenovation;
    }

    get signedPdfUrl(): string | null {
        return this.attributes.signedPdfUrl;
    }

    get wasSentSuccessfully(): boolean {
        return this.meta?.sent_successfully ?? true;
    }

    get canBeDeleted(): boolean {
        const hasPermissionTo = this.meta?.permissions?.delete ?? false;

        return hasPermissionTo ? !this.hasWorksiteProgressBillings() : false;
    }

    /**
     * This should only be found on quotes.
     */
    get depositInvoicesPercentage(): number | null {
        if (this.meta && this.meta.depositInvoicesPercentage) {
            return Math.round(Math.min(this.meta.depositInvoicesPercentage, 100) * 100) / 100;
        }
        return null;
    }

    /**
     * This should only be found on quotes.
     */
    get worksiteProgressBillingsPercentage(): number | null {
        if (this.meta && this.meta.worksiteProgressBillingsPercentage) {
            return this.meta.worksiteProgressBillingsPercentage;
        }
        return null;
    }

    get depositInvoiceData(): Record<string, any> | null {
        return this.attributes.depositInvoiceData;
    }

    get depositInvoicePercentage(): number | null {
        return this.depositInvoiceData?.percentage || null;
    }

    get contactState(): Record<string, any> | null {
        return this.attributes.contactState;
    }

    get frenchVatCertificateUrl(): string | null {
        return this.attributes.frenchVatCertificateUrl;
    }

    get acceptedByData(): any | null {
        return this.attributes.acceptedByData;
    }

    get faIcon(): string {
        switch (this.documentType) {
            case 'quote':
                return 'fa-file-circle-check';
            case 'invoice':
                return 'fa-file-circle-plus';
            case 'credit-note':
                return 'fa-file-circle-minus';
            case 'order-slip':
                return 'fa-shopping-cart';
            case 'delivery-note':
                return 'fa-truck';
            default:
                return 'fa-file';
        }
    }

    openFromSearch(): void {
        this.edit();
    }

    edit(): void {
        navigateTo(new InvoiceRoute(this.documentType).edit(this.id));
    }

    canChangeStatus(): boolean {
        if (this.documentType == 'quote') {
            return this.status !== 'draft';
        }

        // if ( this.documentType == 'invoice' ) {
        //     return this.status !== 'draft' && this.status !== 'paid';
        // }

        return false;
    }

    canRecordPayment(): boolean {
        return this.documentType == 'invoice' && (this.status === 'sent' || this.status === 'overdue') && this.totalDue > 0;
    }

    hasWorksiteProgressBillings(): boolean {
        if (!this.worksiteProgressBillings) return false;

        return this.worksiteProgressBillings.length() > 0;
    }
}
