import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class PdfImage extends BaseModel {
    type: ModelType = ModelType.PDF_IMAGES;
    static type: ModelType = ModelType.PDF_IMAGES;
    prefix: string = 'pdf-image';
    static prefix: string = 'pdf-image';

    get url(): string {
        return this.attributes.url;
    }

    get page(): number | null {
        return this.attributes.page;
    }
}
