import Task from '../models/Task';
import BaseCollection from '@/app/base/collections/BaseCollection';
import { type MomentInstance } from '~~/utils/moment';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class TaskCollection extends BaseCollection<Task> {
    model: ModelType = ModelType.TASKS;

    whereDate(date: MomentInstance): Task[] {
        return this.filter((task: Task) => {
            return task.dueDate?.isSame(date, 'day');
        });
    }
}
