import User from '../models/User';
import BaseCollection from '@/app/base/collections/BaseCollection';
import { BaseSchema, ModelType } from '~~/app/base/schemas/BaseSchema';

export default class UserCollection extends BaseCollection<User> {
    model: ModelType = ModelType.USERS;

    static fromTenantUsers(schemas: Array<typeof BaseSchema>, included: Object = {}, meta: Object = {}): UserCollection {
        const usersSchemas = schemas.map((schema) => {
            schema.id = schema.attributes.userId;
            schema.type = ModelType.USERS;
            schema.attributes = {
                firstName: schema.attributes.firstName,
                lastName: schema.attributes.lastName,
                email: schema.attributes.email,
                phone: schema.attributes.phone,
                avatar: schema.attributes.avatar,
                locale: schema.attributes.locale,
                role: schema.attributes.role,
                permissions: schema.attributes.permissions,
                options: schema.attributes.options,
                isActive: schema.attributes.isActive,
            };
            return schema;
        });
        return new UserCollection(usersSchemas, included, meta);
    }

    defaultItemSortCallback = (a: User, b: User) => {
        if (a.isActive && !b.isActive) {
            return -1;
        }
        if (!a.isActive && b.isActive) {
            return 1;
        }

        if (a.role === 'super-admin' && b.role !== 'super-admin') {
            return -1;
        }
        if (a.role !== 'super-admin' && b.role === 'super-admin') {
            return 1;
        }

        if (a.role === 'admin' && b.role !== 'admin') {
            return -1;
        }
        if (a.role !== 'admin' && b.role === 'admin') {
            return 1;
        }

        if (a.lastName < b.lastName) {
            return -1;
        }
        if (a.lastName > b.lastName) {
            return 1;
        }
        return 0;
    };

    get displayName(): string {
        return this.items.map((user) => user.displayName).join(', ');
    }
}
