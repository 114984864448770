import ValidationStatus from '../../enums/worksite/ValidationStatus';
import { User } from '../../types/User';
import ProgressBillingRoute from '../../../classes/ProgressBillingRoute';
import Worksite from './Worksite';
import WorksiteProgressBillingLine from './WorksiteProgressBillingLine';
import Invoice from '~/app/invoicing/models/Invoice';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import BaseModel from '~~/app/base/models/BaseModel';
import moment, { type MomentInstance } from '~/utils/moment';

export default class WorksiteProgressBilling extends BaseModel {
    type: ModelType = ModelType.WORKSITE_PROGRESS_BILLINGS;
    static type: ModelType = ModelType.WORKSITE_PROGRESS_BILLINGS;
    prefix = 'worksite:worksite-progress-billing';
    static prefix = 'worksite:worksite-progress-billing';

    get relationshipsMap(): Record<string, ModelType> {
        return {
            worksite: ModelType.WORKSITES,
            quote: ModelType.INVOICES,
            invoice: ModelType.INVOICES,
            worksiteProgressBillingLines: ModelType.WORKSITE_PROGRESS_BILLING_LINES,
        };
    }

    // get invoiceSections(): InvoiceSectionCollection | null {
    //     return this.relationships?.worksiteProgressBillingLines?.data || null;
    // }
    // get expense(): Expense | null
    // {
    //     return this.relationships?.expense?.data || null;
    // }
    get worksite(): Worksite | null {
        return this.relationships?.worksite?.data || null;
    }

    get invoice(): Invoice | null {
        return this.relationships?.invoice?.data || null;
    }

    get quote(): Worksite | null {
        return this.relationships?.quote?.data || null;
    }

    get worksiteProgressBillingLines(): WorksiteProgressBillingLine[] | null {
        return this.relationships?.worksiteProgressBillingLines?.data || null;
    }

    get number(): string {
        return this.attributes.number;
    }

    get sendForValidationAt(): string {
        return this.attributes.sendForValidationAt;
    }

    set sendForValidationAt(value: string) {
        this.sendForValidationAt = value;
    }

    get validatedAt(): MomentInstance {
        return moment(this.attributes.validatedAt);
    }

    get pdfId(): string {
        return this.attributes.pdfId;
    }

    get total(): number {
        return this.attributes.total;
    }

    get externalPartyData(): any {
        return this.attributes.externalPartyData;
    }

    get status(): string {
        return this.attributes.status;
    }

    set status(value: ValidationStatus) {
        this.status = value;
    }

    get invoicedAt(): MomentInstance | null {
        if (!this.attributes.invoicedAt) return null;
        return moment(this.attributes.invoicedAt);
    }

    get createdAt(): MomentInstance {
        return moment(this.attributes.createdAt);
    }

    get updatedAt(): MomentInstance | null {
        return moment(this.attributes.updatedAt);
    }

    get hasStatus(): boolean {
        return this.status !== null && this.status !== 'null';
    }

    get user(): User {
        return this.included;
    }

    edit(worksite: Worksite): void {
        navigateTo(new ProgressBillingRoute(worksite.getId()).edit(this.id));
    }
}
