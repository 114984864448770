import TimeActivity from '../models/TimeActivity';

interface TimeActivityCostsInterface {
    hours: number;
    hourlyRate: number | null;
    totalRate: number | null;
    hourlyCost: number | null;
    totalCost: number | null;
}

export default class TimeActivityCosts {
    timeActivity: TimeActivity;
    costs: TimeActivityCostsInterface;

    constructor(timeActivity: TimeActivity) {
        if (!timeActivity.meta?.costs) {
            throw new Error('TimeActivityCosts: TimeActivity has no costs meta');
        }

        this.timeActivity = timeActivity;
        this.costs = timeActivity.meta.costs;
    }

    get hours(): number {
        return this.costs.hours;
    }

    get hourlyRate(): number | null {
        return this.costs.hourlyRate;
    }

    get totalRate(): number | null {
        return this.costs.totalRate;
    }

    get hourlyCost(): number | null {
        return this.costs.hourlyCost;
    }

    get totalCost(): number | null {
        return this.costs.totalCost;
    }
}
