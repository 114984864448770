import PdfImageCollection from '../collections/PdfImageCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

export default class Pdf extends BaseModel {
    type: ModelType = ModelType.PDFS;
    static type: ModelType = ModelType.PDFS;
    prefix: string = 'pdf';
    static prefix: string = 'pdf';

    get pdfImages(): PdfImageCollection | null {
        return this.relationships?.pdfImages?.data;
    }

    get url(): string {
        return this.attributes.url;
    }

    get title(): string {
        const urlParts = this.url.split('/');
        return urlParts[urlParts.length - 1];
    }
}
