import TimeActivity from '../models/TimeActivity';
import BaseCollection from '@/app/base/collections/BaseCollection';
import moment, { type MomentInstance } from '~~/utils/moment';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import User from '~~/app/auth/models/User';

export default class TimeActivityCollection extends BaseCollection<TimeActivity> {
    model: ModelType = ModelType.TIME_ACTIVITIES;

    byDate(date: MomentInstance): TimeActivityCollection {
        const collection = this.clone();
        collection.setItemShouldBeInCollectionCallback((timeActivity: TimeActivity) => timeActivity.startsAt && timeActivity.startsAt.isSame(date, 'day'));
        return collection;
    }

    byUser(user: User): TimeActivityCollection {
        const collection = this.clone();
        collection.setItemShouldBeInCollectionCallback((timeActivity: TimeActivity) => timeActivity?.user?.id === user.id);
        return collection;
    }

    billedItems(): Array<TimeActivity> {
        return this.items.filter((timeActivity: TimeActivity) => timeActivity.invoicedAt !== null);
    }

    unbilledItems(): Array<TimeActivity> {
        return this.items.filter((timeActivity: TimeActivity) => timeActivity.invoicedAt == null);
    }

    totalDurationInSeconds(items: Array<TimeActivity> | null = null): number {
        return (items || this.get()).reduce((total: number, timeActivity: TimeActivity) => total + timeActivity.liveTimer.duration(), 0);
    }

    totalDuration(): moment.Duration {
        return moment.duration(this.totalDurationInSeconds(), 'seconds');
    }

    totalCost(): number {
        return this.items.reduce((total: number, timeActivity: TimeActivity) => total + (timeActivity.costs?.totalCost || 0), 0);
    }

    totalBilledDurationInSeconds(): number {
        return this.totalDurationInSeconds(this.billedItems());
    }

    totalBilledDuration(): string {
        const duration = moment.duration(this.totalBilledDurationInSeconds(), 'seconds');

        const totalHours = Math.floor(duration.asHours());
        const remainingMinutes = Math.floor(duration.asMinutes()) % 60;

        return `${totalHours}h${remainingMinutes.toString().padStart(2, '0')}`;
    }

    totalBilled(): number {
        return this.billedItems().reduce((total: number, timeActivity: TimeActivity) => total + (timeActivity.costs.totalCost || 0), 0);
    }

    totalUnbilledDurationInSeconds(): number {
        return this.totalDurationInSeconds(this.unbilledItems());
    }

    totalUnbilledDuration(): string {
        const duration = moment.duration(this.totalUnbilledDurationInSeconds(), 'seconds');

        const totalHours = Math.floor(duration.asHours());
        const remainingMinutes = Math.floor(duration.asMinutes()) % 60;

        return `${totalHours}h${remainingMinutes.toString().padStart(2, '0')}`;
    }

    totalUnbilled(): number {
        return this.billedItems().reduce((total: number, timeActivity: TimeActivity) => total + (timeActivity.costs.totalCost || 0), 0);
    }
}
