import Invoice from './Invoice';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import moment, { type MomentInstance } from '~~/utils/moment';
import BankAccountTransaction from '@/app/bankaccount/models/BankAccountTransaction';

export default class InvoicePayment extends BaseModel {
    type: ModelType = ModelType.INVOICE_PAYMENTS;
    static type: ModelType = ModelType.INVOICE_PAYMENTS;
    prefix = 'invoicing:invoice-payment';
    static prefix = 'invoicing:invoice-payment';

    get invoice(): Invoice | null {
        return this.relationships?.invoice?.data || null;
    }

    get bankAccountTransaction(): BankAccountTransaction | null {
        return this.relationships?.bankAccountTransaction?.data || null;
    }

    get title(): string | null {
        return this.attributes.title;
    }

    get amount(): number | null {
        return this.attributes.amount;
    }

    get paidAt(): MomentInstance | null {
        return this.attributes.paidAt ? moment(this.attributes.paidAt) : null;
    }

    get paymentMethod(): string | null {
        return this.attributes.paymentMethod;
    }

    get claimRemains(): boolean | null {
        return this.attributes.claimRemains;
    }
}
