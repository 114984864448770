export const MODEL_EVENT_TYPE = {
    // Common
    CREATED: 'created',
    UPDATED: 'updated',
    DELETED: 'deleted',

    // Invoices
    SENT: 'sent',
    ACCEPTED: 'accepted',
    SIGNED: 'signed',
    ACCEPTED_BY_CLIENT: 'accepted_by_client',
    ACCEPTED_FOR_CLIENT: 'accepted_for_client',
    REJECTED: 'rejected',
    NOTIFICATION: 'notification',
    PAID: 'paid',
    OVERDUE: 'overdue',
    DUPLICATED: 'duplicated',
    PAYMENT_CREATED: 'payment_created',
    PAYMENT_UPDATED: 'payment_updated',
    PAYMENT_DELETED: 'payment_deleted',
    PAYMENT_REMINDER: 'payment_reminder',
    DISCOUNT_CREATED: 'discount_created',
    DISCOUNT_UPDATED: 'discount_updated',
    DISCOUNT_DELETED: 'discount_deleted',
    DEPOSIT_INVOICE_CREATED: 'deposit_invoice_created',
    REPLICATED: 'replicated',
    STATUS: 'status',
    EMAIL: 'email',
    EMAIL_DELIVERED: 'email_delivered',
    EMAIL_VIEWED: 'email_viewed',
    EMAIL_CLICKED: 'email_clicked',
    EMAIL_FAILED: 'email_failed',
    SMS: 'sms',
    SMS_FAILED: 'sms_failed',

    // Worksites
    TASK_CREATED: 'task_created',
    TASK_UPDATED: 'task_updated',
    TASK_DELETED: 'task_deleted',
    TASK_COMPLETED: 'task_completed',
    TASK_UNCOMPLETED: 'task_uncompleted',
    TASK_MESSAGE_CREATED: 'task_message_created',
    TIME_ACTIVITY_STARTED: 'time_activity_started',
    TIME_ACTIVITY_STOPPED: 'time_activity_stopped',
    TIME_ACTIVITY_DELETED: 'time_activity_deleted',
    TIME_ACTIVITY_PAUSED: 'time_activity_paused',
    TIME_ACTIVITY_RESTARTED: 'time_activity_restarted',
    TIME_ACTIVITY_MESSAGE_CREATED: 'time_activity_message_created',
    WORKSITE_FILE_UPLOADED: 'worksite_file_uploaded',

    // Closure Report
    REFUSED: 'refused',
    RESERVES_RAISED: 'reserves_raised',
    RESERVES_RAISED_TENANT_SIGNATURE: 'reserves_raised_tenant_signature',
    RESERVES_REMOVED: 'reserves_removed',
    RESERVES_REMOVED_TENANT_SIGNATURE: 'reserves_tenant_signature_removed',
} as const;

export type ModelEventType = Enum<typeof MODEL_EVENT_TYPE>;
