import CalendarEventCollection from '../collections/CalendarEventCollection';
import CalendarEvent from '../models/CalendarEvent';
import CalendarEventSchema from '../schemas/CalendarEventSchema';
import BaseEndpoint from '@/app/base/endpoints/BaseEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import { ModelEndpointResponse } from '~~/app/base/endpoints/types';
import moment, { type MomentInstance } from '~~/utils/moment';

export default class CalendarEventEndpoint extends BaseEndpoint<CalendarEvent, CalendarEventCollection> {
    model: ModelType = ModelType.CALENDAR_EVENTS;
    path = 'tenant/planning/calendar-events';
    include = 'users,worksite,contact,worksitePhase';

    getCalendarEventSchemaForNewDay(model: CalendarEvent, startsAt: MomentInstance, endsAt: MomentInstance): CalendarEventSchema {
        const schema = new CalendarEventSchema({
            attributes: {
                startsAt: startsAt?.toUtcDateTimeString(),
                endsAt: endsAt?.toUtcDateTimeString(),
                title: model.title,
                eventType: model.eventType?.name,
                timetrackingAllowed: !!model.timetrackingAllowed,
                accountedForWeeklyHours: !!model.accountedForWeeklyHours,
                notes: model.notes,
                address: model.address,
            },
        });

        if (model.users) {
            schema.addToManyRelationships('users', ModelType.USERS, model.users.toIds());
        }
        if (model.lead) {
            schema.addToOneRelationship('lead', ModelType.LEADS, model.lead.getId());
        }
        if (model.worksite) {
            schema.addToOneRelationship('worksite', ModelType.WORKSITES, model.worksite.getId());
        }
        if (model.worksitePhase) {
            schema.addToOneRelationship('worksitePhase', ModelType.WORKSITE_PHASES, model.worksitePhase.getId());
        }

        return schema;
    }

    async duplicateNextDay(model: CalendarEvent): Promise<ModelEndpointResponse<CalendarEvent>> {
        if (!model.startsAt || !model.endsAt) {
            throw new Error('CalendarEventEndpoint.duplicateNextDay: model dates are not fully set');
        }

        return await this.store(this.getCalendarEventSchemaForNewDay(model, model.startsAt.clone().add(1, 'day'), model.endsAt.clone().add(1, 'day')));
    }

    async duplicateEndOfWeek(model: CalendarEvent): Promise<void> {
        if (!model.startsAt || !model.endsAt) {
            throw new Error('CalendarEventEndpoint.duplicateEndOfWeek: model dates are not fully set');
        }

        const startDate = model.startsAt.clone().add(1, 'days');
        const friday = startDate.clone().isoWeekday(5);
        const range = moment.range(startDate, friday);

        for (const date of range.by('day')) {
            await this.store(
                this.getCalendarEventSchemaForNewDay(
                    model,
                    date.clone().set('hour', model.startsAt.hour()).set('minute', model.startsAt.minute()),
                    date.clone().set('hour', model.endsAt.hour()).set('minute', model.endsAt.minute())
                )
            );
        }
    }
}
